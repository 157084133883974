import React from 'react'
import { graphql } from 'gatsby'
import {
  Image,
  Header,
  Modal,
  Icon,
  Segment,
  List,
  Grid,
  Table,
  Button,
} from 'semantic-ui-react'
import Img from 'gatsby-image'
import Carousel from 'nuka-carousel'
import { ContactForm, EnquiryForm } from '@ballycastle-accommodation/shared-ui'

import Layout from '../components/layout'

const IndexPage = (props) => (
  <Layout>
    <Header as="h2" size="medium" id="gallery" content="" />

    <Carousel
      autoplay="true"
      wrapAround="true"
      heightMode="current"
      renderCenterLeftControls={({ previousSlide }) => (
        <Button basic circular icon size="mini" onClick={previousSlide}>
          <Icon name="chevron left" />
        </Button>
      )}
      renderCenterRightControls={({ nextSlide }) => (
        <Button basic circular icon size="mini" onClick={nextSlide}>
          <Icon name="chevron right" />
        </Button>
      )}
    >
      <Img fluid={props.data.imageExterior.childImageSharp.fluid} />
      <Img fluid={props.data.imageLivingRoom.childImageSharp.fluid} />
      <Img fluid={props.data.imageLivingRoomReverse.childImageSharp.fluid} />
      <Img fluid={props.data.imageKitchenRight.childImageSharp.fluid} />
      <Img fluid={props.data.imageBedroomMaster.childImageSharp.fluid} />
      <Img fluid={props.data.imageBedroomTwin.childImageSharp.fluid} />
      <Img fluid={props.data.imageBedroomBunk.childImageSharp.fluid} />
      <Img fluid={props.data.imageBathroom.childImageSharp.fluid} />
    </Carousel>

    <Header as="h2" size="medium" id="home" content="Home" />

    <p>
      Strand Cottage is a spacious, modern, well-furnished, 3-bedroom
      semi-detached dwelling in a quiet cul-de-sac, located beside a historic
      river walk to the town centre.
    </p>

    <p>
      The property is a minute away from the seafront shops, cafes, hotel,
      beach, golf club, tennis club, marina and island ferries to Rathlin,
      Sanda, Islay and beyond.
    </p>

    <p>
      Centrally located to the region's most popular tourist attractions within
      a 15-mile radius that encompasses the Giant's Causeway, Glens of Antrim,
      Bushmills Distillery, multiple Game of Thrones® filming sites and much
      more.
    </p>

    <p>Strand Cottage has a 4 Star self-catering grading from Tourism NI.</p>

    <p>Our comfortable accommodation sleeps up to 6 people and includes:</p>

    <List bulleted relaxed>
      <List.Item>A luxury standard of interior décor</List.Item>
      <List.Item>Open plan living/dining room and spacious kitchen</List.Item>
      <List.Item>
        Bedroom 1 consists of a double bed, wardrobe, dressing table and en
        suite bathroom with power shower
      </List.Item>
      <List.Item>
        Bedroom 2 consists of twin beds, wardrobe and dressing table
      </List.Item>
      <List.Item>
        Bedroom 3 consists of single-bed bunks and built-in wardrobe
      </List.Item>
      <List.Item>
        Upstairs bathroom with WC, bath, separate electric shower and wash hand
        basin
      </List.Item>
      <List.Item>
        Fully equipped modern kitchen with fridge, microwave, electric cooker,
        washing machine, fridge freezer and dishwasher
      </List.Item>
      <List.Item>
        Oil-fired central heating and an open fireplace in living room
      </List.Item>
      <List.Item>Bed linen and towels supplied</List.Item>
      <List.Item>Two-car driveway</List.Item>
      <List.Item>BBQ facilities</List.Item>
    </List>

    <Header as="h2" size="medium" id="enquire" content="Enquire" />

    <p>
      Contact us directly for our guaranteed best rates using the form below:
    </p>

    <EnquiryForm />

    <Header as="h2" size="medium" id="explore" content="Explore" />

    <p>Less than five minutes' walk to:</p>

    <List bulleted relaxed>
      <List.Item>Ballycastle Strand</List.Item>
      <List.Item>
        <a
          href="http://www.ballycastlegolfclub.com"
          title="Ballycastle Golf Club"
          target="_blank"
          rel="noopener noreferrer"
        >
          Ballycastle Golf Club
        </a>
      </List.Item>
      <List.Item>
        <a
          href="http://www.ballycastletennisclub.com"
          title="Ballycastle Tennis Club"
          target="_blank"
          rel="noopener noreferrer"
        >
          Ballycastle Tennis Club
        </a>
      </List.Item>
      <List.Item>
        <a
          href="https://www.causewaycoastandglens.gov.uk/see-do/leisure-centres/sheskburn-recreation-centre/club-pulse-fitness-suite"
          title="Pulse Gym and Fitness Suite"
          target="_blank"
          rel="noopener noreferrer"
        >
          Pulse Gym and Fitness Suite
        </a>
      </List.Item>
      <List.Item>Seafront and Beach</List.Item>
      <List.Item>Cafes, Bars and Restaurants</List.Item>
      <List.Item>
        Harbour and{' '}
        <a
          href="https://rathlinballycastleferry.com"
          title="Rathlin Island Ferry"
          target="_blank"
          rel="noopener noreferrer"
        >
          Rathlin Island Ferry
        </a>
      </List.Item>
      <List.Item>
        Town Centre via scenic river walk on the{' '}
        <a
          href="http://www.moyle-council.org/ballycastletowntrail/"
          title="Ballycastle Heritage Trail"
          target="_blank"
          rel="noopener noreferrer"
        >
          Ballycastle Heritage Trail
        </a>
      </List.Item>
      <List.Item>Children's play area</List.Item>
      <List.Item>Fishing</List.Item>
      <List.Item>Ballycastle Bowling Club</List.Item>
      <List.Item>
        <a
          href="http://seahaventherapy.com"
          title="Sea Haven Seaweed Baths and Spa"
          target="_blank"
          rel="noopener noreferrer"
        >
          Sea Haven Seaweed Baths and Spa
        </a>
      </List.Item>
    </List>

    <Modal
      closeIcon
      size="fullscreen"
      trigger={
        <Image floated="left" size="small" rounded>
          <Img
            alt="Children of Lir Sculpture, Ballycastle"
            title="Children of Lir Sculpture, Ballycastle"
            fluid={
              props.data.imageBallycastleChildrenOfLirSculpture.childImageSharp
                .fluid
            }
          />
        </Image>
      }
    >
      <Img
        fluid={
          props.data.imageBallycastleChildrenOfLirSculpture.childImageSharp
            .fluid
        }
      />
    </Modal>

    <p>
      Ballycastle is a historic coastal town with a beach and views to Rathlin
      Island, Scotland's Mull of Kintyre and Fair Head, which can be seen rising
      out of the bay.
    </p>

    <Modal
      closeIcon
      size="fullscreen"
      trigger={
        <Image floated="right" size="small" rounded>
          <Img
            alt="Giant's Causeway"
            title="Giant's Causeway"
            fluid={props.data.imageGiantsCauseway.childImageSharp.fluid}
          />
        </Image>
      }
    >
      <Img fluid={props.data.imageGiantsCauseway.childImageSharp.fluid} />
    </Modal>

    <p>
      Ballycastle is the perfect base to explore the famous Glens of Antrim.
      Only a short drive to the world famous Giant's Causeway, en route to which
      you can visit Carrick-a-Rede Rope Bridge and Whitepark Bay.
    </p>

    <p>
      Explore many of the iconic locations featured in HBO's epic series Game of
      Thrones®, including the Dark Hedges, Ballintoy Harbour, Murlough Bay and
      Fair Head.
    </p>

    <p>
      Ballycastle hosts Ireland's oldest fair, the Lammas Fair, every year on
      the last Monday and Tuesday of August.
    </p>

    <p>
      Glentaise, the most northerly of the nine Glens of Antrim, lies at the
      foot of Knocklayde Mountain.
    </p>

    <Modal
      closeIcon
      size="fullscreen"
      trigger={
        <Image floated="right" size="small" rounded>
          <Img
            alt="Ballycastle Marina"
            title="Ballycastle Marina"
            fluid={props.data.imageBallycastleMarina.childImageSharp.fluid}
          />
        </Image>
      }
    >
      <Img fluid={props.data.imageBallycastleMarina.childImageSharp.fluid} />
    </Modal>

    <p>
      The Carey, Glenshesk and Tow Rivers flow down from the Glens to eventually
      join together into one river, known as the Margy River, which flows into
      the Sea of Moyle at the start of Ballycastle Strand.
    </p>

    <Modal
      closeIcon
      size="fullscreen"
      trigger={
        <Image floated="left" size="small" rounded>
          <Img
            alt="Carrick-a-Rede Rope Bridge"
            title="Carrick-a-Rede Rope Bridge"
            fluid={props.data.imageCarrickARede.childImageSharp.fluid}
          />
        </Image>
      }
    >
      <Img fluid={props.data.imageCarrickARede.childImageSharp.fluid} />
    </Modal>

    <p>The Strand (Ballycastle Beach) has a European Blue Flag.</p>

    <p>
      Pan's Rocks are the remains of an iron salt pan, jutting out to the sea at
      the far end of Ballycastle Beach, are a popular location for fishing.
    </p>

    <p>
      The Devil's Churn, lying just beyond Pan's Rocks, has steps carved into
      the stone leading to an underwater tunnel.
    </p>
    <p>
      A popular cycling route runs from Ballycastle to Cushendun, taking in the
      spectacular scenery of Torr Head and the surrounding Glens.
    </p>

    <p>
      Ballycastle is home to the Rathlin ferry which provides daily crossings to
      Rathlin Island.
    </p>

    <Modal
      closeIcon
      size="fullscreen"
      trigger={
        <Image floated="right" size="small" rounded>
          <Img
            alt="Bonamargy Friary, Ballycastle"
            title="Bonamargy Friary, Ballycastle"
            fluid={
              props.data.imageBallycastleBonamargyFriary.childImageSharp.fluid
            }
          />
        </Image>
      }
    >
      <Img
        fluid={props.data.imageBallycastleBonamargyFriary.childImageSharp.fluid}
      />
    </Modal>

    <p>
      Bonamargy Friary is a late Franciscan institution established in 1485. The
      Friary's most famous resident is the 17th-century prophet and recluse
      Julia MacQuillan, known locally as "The Black Nun".
    </p>

    <p>
      Kinbane Castle is situated on a headland projecting into the sea, about 3
      miles from Ballycastle on the road to Ballintoy.
    </p>

    <p>There are several churches in Ballycastle.</p>

    <p>
      Shopping towns of Coleraine and Ballymena are 20 and 30 minutes' drive
      away respectively.
    </p>

    <Header as="h2" size="medium" id="contact" content="Contact" />

    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2069.222502605118!2d-6.240674000000012!3d55.202922!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x824bd0f9297281ba!2sStrand+Cottage+Self+Catering+Accommodation!5e0!3m2!1sen!2suk!4v1550350411027"
      width="100%"
      height="450"
      title="Google Maps"
      allowFullScreen
    />

    <p>
      For any enquiries or to make a booking please contact Mrs M. Jameson on:
    </p>

    <Grid columns={2} stackable padded="vertically">
      <Grid.Row>
        <Grid.Column verticalAlign="middle">
          <Table collapsing compact="very" fixed unstackable basic="very">
            <Table.Row>
              <Table.Cell>
                <Icon circular name="phone" />
              </Table.Cell>
              <Table.Cell>
                <a title="Landline Phone Number" href="tel:+44-28-2076-2385">
                  +44 (0)28 2076 2385
                </a>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <Icon circular name="mobile alternate" />
              </Table.Cell>
              <Table.Cell>
                <a title="Mobile Phone Number" href="tel:+44-77-0985-1560">
                  +44 (0)77 0985 1560
                </a>
              </Table.Cell>
            </Table.Row>
          </Table>
        </Grid.Column>
        <Grid.Column verticalAlign="middle">
          <Table collapsing compact="very" fixed unstackable basic="very">
            <Table.Row title="Postal Address">
              <Table.Cell>
                <Icon circular name="mail outline" />
              </Table.Cell>
              <Table.Cell>
                <List>
                  <li>20 Sheskburn Avenue</li>
                  <li>Ballycastle</li>
                  <li>Co. Antrim</li>
                  <li>Northern Ireland</li>
                  <li>BT54 6BL</li>
                </List>
              </Table.Cell>
            </Table.Row>
          </Table>
        </Grid.Column>
      </Grid.Row>
    </Grid>

    <p>Or send us an email using the form below:</p>

    <ContactForm />

    <Segment inverted color="grey">
      <Grid relaxed columns={2}>
        <Grid.Row>
          <Grid.Column stretched width={1}>
            <Grid.Row>
              <a
                title="Facebook"
                href="http://www.facebook.com/pages/Hillsea-Bed-Breakfast/219252024758236"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon size="large" inverted name="facebook" />
              </a>
            </Grid.Row>
            <Grid.Row>
              <a
                title="Twitter"
                href="http://twitter.com/hillseabandb"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon size="large" inverted name="twitter" />
              </a>
            </Grid.Row>
            <Grid.Row>
              <a
                title="Flickr"
                href="http://www.flickr.com/photos/hillseabandb/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon size="large" inverted name="flickr" />
              </a>
            </Grid.Row>
          </Grid.Column>
          <Grid.Column>
            <a
              title="Discover Northern Ireland"
              href="https://discovernorthernireland.com/Strand-Cottage-Ballycastle-P7497/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image size="tiny">
                <Img fixed={props.data.imageTNILogo.childImageSharp.fixed} />
              </Image>
            </a>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>

    <Segment basic textAlign="center">
      <List link horizontal divided>
        <List.Item as="a" href={`${process.env.HUB_URL}`}>
          Check out our other properties...
        </List.Item>
        <List.Item>Privacy policy</List.Item>
        <List.Item>
          Copyright © {new Date().getFullYear()}. All Rights Reserved.
        </List.Item>
        <List.Item>Built by James McDonnell</List.Item>
      </List>
    </Segment>
  </Layout>
)

export default IndexPage

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 6000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const query = graphql`
  query {
    imageTNILogo: file(
      relativePath: { eq: "tourism_northern_ireland_logo.png" }
    ) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    imageBallycastleChildrenOfLirSculpture: file(
      relativePath: {
        eq: "attractions/Children-Of-Lir-Swan-Sculpture-Ballycastle.JPG"
      }
    ) {
      ...fluidImage
    }
    imageGiantsCauseway: file(
      relativePath: { eq: "attractions/Giant's-Causeway.JPG" }
    ) {
      ...fluidImage
    }
    imageCarrickARede: file(
      relativePath: { eq: "attractions/Carrick-A-Rede-Rope-Bridge.JPG" }
    ) {
      ...fluidImage
    }
    imageBallycastleMarina: file(
      relativePath: { eq: "attractions/Ballycastle-Marina.JPG" }
    ) {
      ...fluidImage
    }
    imageBallycastleBonamargyFriary: file(
      relativePath: { eq: "attractions/Bonamargy-Friary-Ballycastle.JPG" }
    ) {
      ...fluidImage
    }
    imageExterior: file(
      relativePath: { eq: "strand/Strand-Cottage-Exterior.JPG" }
    ) {
      ...fluidImage
    }
    imageLivingRoom: file(
      relativePath: { eq: "strand/Strand-Cottage-Lounge-To-Back.JPG" }
    ) {
      ...fluidImage
    }
    imageLivingRoomReverse: file(
      relativePath: { eq: "strand/Strand-Cottage-Lounge-To-Front.JPG" }
    ) {
      ...fluidImage
    }
    imageKitchenRight: file(
      relativePath: { eq: "strand/Strand-Cottage-Kitchen-Dining-Room.JPG" }
    ) {
      ...fluidImage
    }
    imageBedroomMaster: file(
      relativePath: { eq: "strand/Strand-Cottage-Master-Bedroom-Ensuite.JPG" }
    ) {
      ...fluidImage
    }
    imageBedroomTwin: file(
      relativePath: { eq: "strand/Strand-Cottage-Twin-Bedroom.JPG" }
    ) {
      ...fluidImage
    }
    imageBedroomBunk: file(
      relativePath: { eq: "strand/Strand-Cottage-Twin-Bedroom-Bunk-Beds.JPG" }
    ) {
      ...fluidImage
    }
    imageBathroom: file(
      relativePath: { eq: "strand/Strand-Cottage-Bathroom.JPG" }
    ) {
      ...fluidImage
    }
  }
`
